
                        require.config({
                            waitSeconds: 20,
                            shim: {
                                'backend/actions/helo': {
                            init: function () {
                                return this.WallabyBackendModules['backend/actions/helo'];
                            }
                        },
'backend/actions/logoutAndReset': {
                            init: function () {
                                return this.WallabyBackendModules['backend/actions/logoutAndReset'];
                            }
                        },
'utils/index': {
                            init: function () {
                                return this.WallabyBackendModules['utils/index'];
                            }
                        },
'backend/eventEmitter': {
                            init: function () {
                                return this.WallabyBackendModules['backend/eventEmitter'];
                            }
                        },
'backend/screen': {
                            init: function () {
                                return this.WallabyBackendModules['backend/screen'];
                            }
                        },
'backend/actions/genericAction': {
                            init: function () {
                                return this.WallabyBackendModules['backend/actions/genericAction'];
                            }
                        },
'backend/actions/paginate': {
                            init: function () {
                                return this.WallabyBackendModules['backend/actions/paginate'];
                            }
                        },
'backend/actions/refresh': {
                            init: function () {
                                return this.WallabyBackendModules['backend/actions/refresh'];
                            }
                        },
'backend/actions/screenHelper': {
                            init: function () {
                                return this.WallabyBackendModules['backend/actions/screenHelper'];
                            }
                        }
                            }
                        });
                    